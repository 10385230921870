.animated-mdalert {
    position: fixed;
    top: 10px;
    right: 20px;
    z-index: 1500; /* Ensure the alert is on top of other content */
    animation: slideIn 0.5s ease-out forwards;
  }
  
  /* Slide-in animation */
  @keyframes slideIn {
    from {
      opacity: 0;
      transform: translateX(100%); /* Start from outside the viewport */
    }
    to {
      opacity: 1;
      transform: translateX(0); /* Slide into place */
    }
  }
  