/* Styles for ConfirmDeleteAlert */

.btn-danger {
    background-color: #f44336; /* Material red color for danger */
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 4px;
    font-weight: bold;
    cursor: pointer;
    transition: background-color 0.3s ease;
    margin-right: 10px; /* Space between buttons */
  }
  
  .btn-danger:hover {
    background-color: #d32f2f; /* Darker red on hover */
  }
  
  .btn-secondary {
    background-color: #9e9e9e; /* Material grey for cancel */
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 4px;
    font-weight: bold;
    cursor: pointer;
    /* transition: background-color 0.3s ease; */
  }
  
  .btn-secondary:hover {
    background-color: #757575; /* Darker grey on hover */
  }
  
  /* Customize SweetAlert appearance */
  .sweet-alert {
    border-radius: 8px; /* Rounded corners for alert box */
    padding: 10px; /* Extra padding for a clean look */
    font-size: 1rem !important;
    font-family: 'Roboto', sans-serif; /* Material Dashboard font */
  }
  
  .sweet-alert h2 {
    font-size: 1.5rem !important;
    font-weight: bold;
    margin-bottom: 10px;
  }
  
  .sweet-alert p {
    font-size: 1rem;
    color: #333;
  }  
  